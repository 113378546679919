/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CHQEarningCodeV2 = {
    id?: string;
    company: string;
    type: CHQEarningCodeV2.type;
    name: string;
    active: boolean;
};

export namespace CHQEarningCodeV2 {

    export enum type {
        HOURLY = 'hourly',
        SALARIED = 'salaried',
        OVERTIME = 'overtime',
        DOUBLE_OVERTIME = 'double_overtime',
        OTHER_IMPUTED = 'other_imputed',
        REGULAR = 'regular',
        REST_AND_RECOVERY = 'rest_and_recovery',
        NON_PRODUCTIVE = 'non_productive',
        PIECE = 'piece',
        PAID_HOLIDAY = 'paid_holiday',
        PTO = 'pto',
        SICK = 'sick',
        PIECE_ADJUSTMENT_TO_MINIMUM_WAGE = 'piece_adjustment_to_minimum_wage',
        WEIGHTED_OVERTIME = 'weighted_overtime',
        WEIGHTED_DOUBLE_OVERTIME = 'weighted_double_overtime',
        TIP_CREDIT_ADJUSTMENT_TO_MINIMUM_WAGES = 'tip_credit_adjustment_to_minimum_wages',
        BONUS = 'bonus',
        COMMISSION = 'commission',
        SEVERANCE = 'severance',
        CASH_TIPS = 'cash_tips',
        PAYCHECK_TIPS = 'paycheck_tips',
        GROUP_TERM_LIFE = 'group_term_life',
        NON_HOURLY_REGULAR = 'non_hourly_regular',
        REIMBURSEMENT = 'reimbursement',
        _2_PERCENT_SHAREHOLDER_BENEFITS = '2_percent_shareholder_benefits',
        _2_PERCENT_SHAREHOLDER_HSA = '2_percent_shareholder_hsa',
    }


}

