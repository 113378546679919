/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { File } from '../models/File';
import type { FileCreate } from '../models/FileCreate';
import type { InventoryItemAssignmentHistoricalMapping } from '../models/InventoryItemAssignmentHistoricalMapping';
import type { Item } from '../models/Item';
import type { ItemCreate } from '../models/ItemCreate';
import type { ItemUpdate } from '../models/ItemUpdate';
import type { ItemWithFiles } from '../models/ItemWithFiles';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ItemService {

    /**
     * Read Items
     * List all items.
     *
     * Args:
     * db (AsyncSession): Database session.
     *
     * Returns:
     * Sequence[schemas.ItemWithFiles]: A sequence of item objects with files.
     * @returns ItemWithFiles Successful Response
     * @throws ApiError
     */
    public static readItemsApiV1InventoryItemsGet(): CancelablePromise<Array<ItemWithFiles>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/inventory/items',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Items Endpoint
     * Create multiple items in one request.
     *
     * Args:
     * items_data (Sequence[schemas.ItemCreate]): Item details to create.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * Sequence[schemas.Item]: The created item objects.
     * @param requestBody
     * @returns Item Successful Response
     * @throws ApiError
     */
    public static createItemsEndpointApiV1InventoryItemsPost(
        requestBody: Array<ItemCreate>,
    ): CancelablePromise<Array<Item>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/inventory/items',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Item
     * Get item by ID. This will return the most up-to-date version of the item, no matter where in the version chain it is.
     *
     * Args:
     * item_id (UUID): Unique identifier of the item.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * schemas.Item | None: The item object, or None if not found.
     * @param itemId
     * @returns Item Successful Response
     * @throws ApiError
     */
    public static readItemApiV1InventoryItemsItemIdGet(
        itemId: string,
    ): CancelablePromise<Item> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/inventory/items/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Item Endpoint
     * Update item by ID. Under the hood, this may create a new version of the item, abstracted away from the user.
     *
     * Args:
     * item_id (UUID): Unique identifier of the item.
     * item_update (schemas.ItemUpdate): Updated item details.
     * db (AsyncSession): Database session.
     * admin_user (schemas.AdminUser): Admin user object.
     *
     * Returns:
     * schemas.Item: The updated item object.
     *
     * Raises:
     * HTTPException: If the item is not found (404).
     * @param itemId
     * @param requestBody
     * @returns Item Successful Response
     * @throws ApiError
     */
    public static updateItemEndpointApiV1InventoryItemsItemIdPatch(
        itemId: string,
        requestBody: ItemUpdate,
    ): CancelablePromise<Item> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/inventory/items/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Item Versions
     * Get all versions of an item.
     *
     * Args:
     * item_id (UUID): Unique identifier of the item.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * Sequence[schemas.Item]: A sequence of item objects.
     * @param itemId
     * @returns Item Successful Response
     * @throws ApiError
     */
    public static readItemVersionsApiV1InventoryItemsItemIdVersionsGet(
        itemId: string,
    ): CancelablePromise<Array<Item>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/inventory/items/{item_id}/versions',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Item Assignments
     * @param itemId
     * @returns InventoryItemAssignmentHistoricalMapping Successful Response
     * @throws ApiError
     */
    public static readItemAssignmentsApiV1InventoryItemsItemIdAssignmentsGet(
        itemId: string,
    ): CancelablePromise<Array<InventoryItemAssignmentHistoricalMapping>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/inventory/items/{item_id}/assignments',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Items For Template
     * @param itemTemplateId
     * @returns ItemWithFiles Successful Response
     * @throws ApiError
     */
    public static getItemsForTemplateApiV1InventoryTemplatesItemTemplateIdItemsGet(
        itemTemplateId: string,
    ): CancelablePromise<Array<ItemWithFiles>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/inventory/templates/{item_template_id}/items',
            path: {
                'item_template_id': itemTemplateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Files To Item
     * @param itemId
     * @param requestBody
     * @returns File Successful Response
     * @throws ApiError
     */
    public static addFilesToItemApiV1InventoryItemsItemIdFilesPost(
        itemId: string,
        requestBody: Array<FileCreate>,
    ): CancelablePromise<Array<File>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/inventory/items/{item_id}/files',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Files From Item
     * @param itemId
     * @returns File Successful Response
     * @throws ApiError
     */
    public static getFilesFromItemApiV1InventoryItemsItemIdFilesGet(
        itemId: string,
    ): CancelablePromise<Array<File>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/inventory/items/{item_id}/files',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Files From Item
     * @param itemId
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteFilesFromItemApiV1InventoryItemsItemIdFilesDelete(
        itemId: string,
        requestBody: Array<string>,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/inventory/items/{item_id}/files',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Update Price
     * @param priceCents
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static bulkUpdatePriceApiV1InventoryItemsBulkPricePost(
        priceCents: number,
        requestBody: Array<string>,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/inventory/items/bulk/price',
            query: {
                'price_cents': priceCents,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Set Sold
     * @param priceSoldCents
     * @param dateOfSale
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static bulkSetSoldApiV1InventoryItemsBulkSoldPost(
        priceSoldCents: number,
        dateOfSale: string,
        requestBody: Array<string>,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/inventory/items/bulk/sold',
            query: {
                'price_sold_cents': priceSoldCents,
                'date_of_sale': dateOfSale,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Set Writeoff
     * @param dateOfWriteoff
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static bulkSetWriteoffApiV1InventoryItemsBulkWriteoffPost(
        dateOfWriteoff: string,
        requestBody: Array<string>,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/inventory/items/bulk/writeoff',
            query: {
                'date_of_writeoff': dateOfWriteoff,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
