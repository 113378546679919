/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActivityCustomer } from './ActivityCustomer';
import type { ActivityOfficer } from './ActivityOfficer';
import type { ActivityType } from './ActivityType';
import type { OfficerLocation } from './OfficerLocation';
import type { TourInstance } from './TourInstance';

export type TourStartedWithLocation = {
    activity_type: ActivityType;
    description?: string;
    id: string;
    title: string;
    index?: number;
    date?: string;
    shift_id?: string;
    customer_id: string;
    site_visit_id?: string;
    patrol_site?: ActivityCustomer;
    officer_id: string;
    officer: ActivityOfficer;
    created_at: string;
    occurred_at: string;
    post_name?: string;
    location?: string;
    tour_instance_id: string;
    tour_instance: TourInstance;
    activity_type_literal?: TourStartedWithLocation.activity_type_literal;
    officer_location?: OfficerLocation;
};

export namespace TourStartedWithLocation {

    export enum activity_type_literal {
        TOUR_STARTED = 'TOUR_STARTED',
    }


}

