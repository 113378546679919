/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ItemTemplate } from '../models/ItemTemplate';
import type { ItemTemplateCreate } from '../models/ItemTemplateCreate';
import type { ItemTemplateUpdate } from '../models/ItemTemplateUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ItemTemplateService {

    /**
     * Read Item Templates
     * List all item templates.
     *
     * Args:
     * db (AsyncSession): Database session.
     *
     * Returns:
     * Sequence[ItemTemplate]: A sequence of item template objects.
     * @returns ItemTemplate Successful Response
     * @throws ApiError
     */
    public static readItemTemplatesApiV1InventoryItemtemplatesGet(): CancelablePromise<Array<ItemTemplate>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/inventory/itemtemplates',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Item Templates Endpoint
     * Create multiple item templates in one request.
     *
     * Args:
     * items_data (Sequence[schemas.ItemTemplateCreate]): Item template details to create.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * Sequence[schemas.ItemTemplate]: The created item template objects.
     * @param requestBody
     * @returns ItemTemplate Successful Response
     * @throws ApiError
     */
    public static createItemTemplatesEndpointApiV1InventoryItemtemplatesPost(
        requestBody: Array<ItemTemplateCreate>,
    ): CancelablePromise<Array<ItemTemplate>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/inventory/itemtemplates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Item Template
     * Get item template by ID. This will return the most up-to-date version of the item template, no matter where in the version chain it is.
     *
     * Args:
     * item_template_id (UUID): Unique identifier of the item template.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * schemas.ItemTemplate | None: The item template object, or None if not found.
     * @param itemTemplateId
     * @returns ItemTemplate Successful Response
     * @throws ApiError
     */
    public static readItemTemplateApiV1InventoryItemtemplateItemTemplateIdGet(
        itemTemplateId: string,
    ): CancelablePromise<ItemTemplate> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/inventory/itemtemplate/{item_template_id}',
            path: {
                'item_template_id': itemTemplateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Item Template Versions
     * Get all versions of an item template.
     *
     * Args:
     * item_template_id (UUID): Unique identifier of the item template.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * Sequence[schemas.ItemTemplate]: A sequence of item template objects.
     * @param itemTemplateId
     * @returns ItemTemplate Successful Response
     * @throws ApiError
     */
    public static readItemTemplateVersionsApiV1InventoryItemtemplateItemTemplateIdVersionsGet(
        itemTemplateId: string,
    ): CancelablePromise<Array<ItemTemplate>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/inventory/itemtemplate/{item_template_id}/versions',
            path: {
                'item_template_id': itemTemplateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Item Template Endpoint
     * Update item template by ID. Under the hood, this may create a new version of the item template, abstracted away from the user.
     *
     * Args:
     * item_template_id (UUID): Unique identifier of the item template.
     * item_template_update (schemas.ItemTemplateUpdate): Updated item template details.
     * db (AsyncSession): Database session.
     * admin_user (schemas.AdminUser): Admin user object.
     *
     * Returns:
     * schemas.ItemTemplate: The updated item template object.
     *
     * Raises:
     * HTTPException: If the item template is not found (404).
     * @param itemTemplateId
     * @param requestBody
     * @returns ItemTemplate Successful Response
     * @throws ApiError
     */
    public static updateItemTemplateEndpointApiV1InventoryItemtemplatesItemTemplateIdPatch(
        itemTemplateId: string,
        requestBody: ItemTemplateUpdate,
    ): CancelablePromise<ItemTemplate> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/inventory/itemtemplates/{item_template_id}',
            path: {
                'item_template_id': itemTemplateId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
