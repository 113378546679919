/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_end_tour_api_v2_tour_end__id__post } from '../models/Body_end_tour_api_v2_tour_end__id__post';
import type { CheckpointActionInstanceUpdate } from '../models/CheckpointActionInstanceUpdate';
import type { GroupedTourInstances } from '../models/GroupedTourInstances';
import type { OfficerLocationCreate } from '../models/OfficerLocationCreate';
import type { TourInstance } from '../models/TourInstance';
import type { TourInstanceUpdate } from '../models/TourInstanceUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TourInstanceService {

    /**
     * Employee Read Tour Instances For Shift Instance
     * Return the tour instances for the given shift instance
     * @param shiftId
     * @param date
     * @param index
     * @returns TourInstance Successful Response
     * @throws ApiError
     */
    public static employeeReadTourInstancesForShiftInstanceApiV1TourInstancesShiftIdDateIndexGet(
        shiftId: string,
        date: string,
        index: number,
    ): CancelablePromise<Array<TourInstance>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tour-instances/{shift_id}/{date}/{index}',
            path: {
                'shift_id': shiftId,
                'date': date,
                'index': index,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Employee Read Tour Instance For Id
     * Return the tour instances for the given shift instance
     * @param id
     * @returns TourInstance Successful Response
     * @throws ApiError
     */
    public static employeeReadTourInstanceForIdApiV1TourInstancesIdGet(
        id: string,
    ): CancelablePromise<TourInstance> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tour-instances/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Employee Read Grouped Tour Instances For Shift Instance
     * Return the tour instances for the given shift instance, grouped by tour admin id (random uuid if unavailable)
     * Decorates the grouped tour instances with a flag that suggests whether an ad hoc tour can be run
     * @param shiftId
     * @param date
     * @param index
     * @returns GroupedTourInstances Successful Response
     * @throws ApiError
     */
    public static employeeReadGroupedTourInstancesForShiftInstanceApiV1GroupedTourInstancesShiftIdDateIndexGet(
        shiftId: string,
        date: string,
        index: number,
    ): CancelablePromise<Record<string, GroupedTourInstances>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/grouped-tour_instances/{shift_id}/{date}/{index}',
            path: {
                'shift_id': shiftId,
                'date': date,
                'index': index,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create And Start Ad Hoc Tour
     * @param tourAdminId
     * @param shiftId
     * @param date
     * @param index
     * @param customerId
     * @param siteVisitOperationId
     * @param requestBody
     * @returns TourInstance Successful Response
     * @throws ApiError
     */
    public static createAndStartAdHocTourApiV1AdHocTourTourAdminIdShiftShiftIdDateIndexPost(
        tourAdminId: string,
        shiftId: string,
        date: string,
        index: number,
        customerId?: (string | null),
        siteVisitOperationId?: (string | null),
        requestBody?: (OfficerLocationCreate | null),
    ): CancelablePromise<TourInstance> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/ad-hoc-tour/{tour_admin_id}/shift/{shift_id}/{date}/{index}',
            path: {
                'tour_admin_id': tourAdminId,
                'shift_id': shiftId,
                'date': date,
                'index': index,
            },
            query: {
                'customer_id': customerId,
                'site_visit_operation_id': siteVisitOperationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Start Tour
     * @param id
     * @param customerId
     * @param requestBody
     * @returns TourInstance Successful Response
     * @throws ApiError
     */
    public static startTourApiV1TourStartIdPost(
        id: string,
        customerId?: (string | null),
        requestBody?: (OfficerLocationCreate | null),
    ): CancelablePromise<TourInstance> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/tour-start/{id}',
            path: {
                'id': id,
            },
            query: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Checkpoint Action
     * @param id
     * @param checkpointId
     * @param checkpointActionId
     * @param requestBody
     * @returns TourInstance Successful Response
     * @throws ApiError
     */
    public static updateCheckpointActionApiV1TourIdCheckpointCheckpointIdCheckpointActionCheckpointActionIdPost(
        id: string,
        checkpointId: string,
        checkpointActionId: string,
        requestBody: CheckpointActionInstanceUpdate,
    ): CancelablePromise<TourInstance> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/tour/{id}/checkpoint/{checkpoint_id}/checkpoint-action/{checkpoint_action_id}',
            path: {
                'id': id,
                'checkpoint_id': checkpointId,
                'checkpoint_action_id': checkpointActionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Scan Checkpoint
     * Accepts tour instance id and checkpoint id (not instance)
     * @param id
     * @param checkpointId
     * @param customerId
     * @param requestBody
     * @returns TourInstance Successful Response
     * @throws ApiError
     */
    public static scanCheckpointApiV1TourIdCheckpointCheckpointIdPost(
        id: string,
        checkpointId: string,
        customerId?: (string | null),
        requestBody?: (OfficerLocationCreate | null),
    ): CancelablePromise<TourInstance> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/tour/{id}/checkpoint/{checkpoint_id}',
            path: {
                'id': id,
                'checkpoint_id': checkpointId,
            },
            query: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * End Tour
     * @param id
     * @param requestBody
     * @param customerId
     * @returns TourInstance Successful Response
     * @throws ApiError
     */
    public static endTourApiV1TourEndIdPost(
        id: string,
        requestBody: TourInstanceUpdate,
        customerId?: (string | null),
    ): CancelablePromise<TourInstance> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/tour-end/{id}',
            path: {
                'id': id,
            },
            query: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * End Tour
     * @param id
     * @param requestBody
     * @param customerId
     * @returns TourInstance Successful Response
     * @throws ApiError
     */
    public static endTourApiV2TourEndIdPost(
        id: string,
        requestBody: Body_end_tour_api_v2_tour_end__id__post,
        customerId?: (string | null),
    ): CancelablePromise<TourInstance> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/tour-end/{id}',
            path: {
                'id': id,
            },
            query: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
