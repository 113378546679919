/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActivityOfficer } from './ActivityOfficer';
import type { ActivityUnionWithLocation } from './ActivityUnionWithLocation';
import type { BreakInstance } from './BreakInstance';
import type { ClockInStatus } from './ClockInStatus';
import type { OfficerLocation } from './OfficerLocation';
import type { Runsheet } from './Runsheet';
import type { ShiftInstanceStatus } from './ShiftInstanceStatus';
import type { Site } from './Site';
import type { TourInstance } from './TourInstance';

export type ShiftInstanceForLiveDrilldown = {
    shift_id: string;
    date: string;
    post_name: string;
    shift_start: string;
    shift_length: number;
    billing_rate?: number;
    expected_pay_rate?: number;
    officer_id?: string;
    default_officer_id?: string;
    status: ShiftInstanceStatus;
    clock_in_status?: ClockInStatus;
    index?: number;
    confirmed_at?: string;
    confirmed_by_admin?: boolean;
    pay_on_shift?: boolean;
    post_pay_rate?: number;
    wage_override?: number;
    billing_rate_override?: number;
    split_shift?: boolean;
    tk_approved?: boolean;
    tk_was_via_auto_approve?: boolean;
    was_clock_out_via_auto?: boolean;
    shift_start_date_time?: string;
    shift_end_date_time?: string;
    is_training_shift?: boolean;
    bill_daily_ot?: boolean;
    bill_weekly_ot?: boolean;
    override_billing_times?: boolean;
    firm_id: string;
    clock_in?: string;
    clock_out?: string;
    officer_locations: Array<OfficerLocation>;
    site?: Site;
    officer?: ActivityOfficer;
    address?: string;
    iana_timezone: string;
    customer_name: string;
    customer_type: ShiftInstanceForLiveDrilldown.customer_type;
    customer_id: string;
    activities?: Array<ActivityUnionWithLocation>;
    break_instances?: Array<BreakInstance>;
    tour_instances?: Array<TourInstance>;
    number_of_breaks?: number;
    runsheet?: Runsheet;
};

export namespace ShiftInstanceForLiveDrilldown {

    export enum customer_type {
        SITE = 'SITE',
        PATROL = 'PATROL',
    }


}

