/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Comment } from '../models/Comment';
import type { CommentCreateParams } from '../models/CommentCreateParams';
import type { CommentUpdateParams } from '../models/CommentUpdateParams';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommentService {

    /**
     * Create Comment
     * @param dispatchId
     * @param requestBody
     * @returns Comment Successful Response
     * @throws ApiError
     */
    public static createCommentApiV1DispatchDispatchIdCommentPost(
        dispatchId: string,
        requestBody: CommentCreateParams,
    ): CancelablePromise<Comment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/dispatch/{dispatch_id}/comment',
            path: {
                'dispatch_id': dispatchId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Comment
     * @param id
     * @returns Comment Successful Response
     * @throws ApiError
     */
    public static getCommentApiV1CommentIdGet(
        id: string,
    ): CancelablePromise<Comment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/comment/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Comment
     * @param id
     * @param requestBody
     * @returns Comment Successful Response
     * @throws ApiError
     */
    public static updateCommentApiV1CommentIdPatch(
        id: string,
        requestBody: CommentUpdateParams,
    ): CancelablePromise<Comment> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/comment/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Comment
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteCommentApiV1CommentIdDelete(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/comment/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Comments For Dispatch
     * @param dispatchId
     * @returns Comment Successful Response
     * @throws ApiError
     */
    public static getCommentsForDispatchApiV1DispatchDispatchIdCommentsGet(
        dispatchId: string,
    ): CancelablePromise<Array<Comment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dispatch/{dispatch_id}/comments',
            path: {
                'dispatch_id': dispatchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Comment Admin
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteCommentAdminApiV1AdminCommentIdDelete(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/admin/comment/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
