/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_send_shift_instances_for_date_range_to_payroll_api_v2_timecards_shifts_payroll_period_post } from '../models/Body_send_shift_instances_for_date_range_to_payroll_api_v2_timecards_shifts_payroll_period_post';
import type { Body_send_shift_instances_for_date_range_to_payroll_api_v3_timecards_shifts_payroll_period_post } from '../models/Body_send_shift_instances_for_date_range_to_payroll_api_v3_timecards_shifts_payroll_period_post';
import type { Body_send_shift_instances_for_date_range_to_payroll_rollout_api_v3_timecards_shifts_payroll_period_rollout_post } from '../models/Body_send_shift_instances_for_date_range_to_payroll_rollout_api_v3_timecards_shifts_payroll_period_rollout_post';
import type { FlatratePayrollResponse } from '../models/FlatratePayrollResponse';
import type { OfficerForTimekeepingView } from '../models/OfficerForTimekeepingView';
import type { OfficerTimecard } from '../models/OfficerTimecard';
import type { OfficerTimekeepingParams } from '../models/OfficerTimekeepingParams';
import type { PayrollServicesEnum } from '../models/PayrollServicesEnum';
import type { PayrollV2RolloutResponse } from '../models/PayrollV2RolloutResponse';
import type { ScheduleShiftInstance } from '../models/ScheduleShiftInstance';
import type { ShiftDetails } from '../models/ShiftDetails';
import type { ShiftDetailsUpdate } from '../models/ShiftDetailsUpdate';
import type { ShiftInstanceTimekeepingUpdate } from '../models/ShiftInstanceTimekeepingUpdate';
import type { TimecardShiftInstanceLegacy } from '../models/TimecardShiftInstanceLegacy';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TimecardService {

    /**
     * Get Shift Instances For Date
     * Returns the published shift instances for a given date and firm
     * @param targetDate
     * @param startDate
     * @param endDate
     * @returns ScheduleShiftInstance Successful Response
     * @throws ApiError
     */
    public static getShiftInstancesForDateApiV1TimecardsShiftsDayGet(
        targetDate: string,
        startDate: string,
        endDate: string,
    ): CancelablePromise<Array<ScheduleShiftInstance>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/timecards_shifts/day',
            query: {
                'target_date': targetDate,
                'start_date': startDate,
                'end_date': endDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shift Instances For Date Range
     * Returns the published shift instances for between start and end dates for firm
     * @param startDate
     * @param endDate
     * @param payGroupId
     * @returns ScheduleShiftInstance Successful Response
     * @throws ApiError
     */
    public static getShiftInstancesForDateRangeApiV1TimecardsShiftsPeriodGet(
        startDate: string,
        endDate: string,
        payGroupId?: (string | null),
    ): CancelablePromise<Array<ScheduleShiftInstance>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/timecards_shifts/period',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'pay_group_id': payGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Single Shift Instance Actual Details By Id
     * @param shiftInstanceId
     * @param startDate
     * @param endDate
     * @returns ShiftDetails Successful Response
     * @throws ApiError
     */
    public static getSingleShiftInstanceActualDetailsByIdApiV1TimecardsSingleShiftShiftInstanceIdGet(
        shiftInstanceId: string,
        startDate: string,
        endDate: string,
    ): CancelablePromise<ShiftDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/timecards_single_shift/{shift_instance_id}',
            path: {
                'shift_instance_id': shiftInstanceId,
            },
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Export For Officer Timekeeping
     * Return the officer timekeeping as pdf
     * @param officerId
     * @param startDate
     * @param endDate
     * @param payGroupId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static exportForOfficerTimekeepingApiV1TimecardsOfficerExportOfficerIdGet(
        officerId: string,
        startDate: string,
        endDate: string,
        payGroupId?: (string | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/timecards/officer/export/{officer_id}',
            path: {
                'officer_id': officerId,
            },
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'pay_group_id': payGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Timecard Settings For Officer
     * @returns OfficerTimekeepingParams Successful Response
     * @throws ApiError
     */
    public static getTimecardSettingsForOfficerApiV1TimecardsSettingsOfficerGet(): CancelablePromise<OfficerTimekeepingParams> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/timecards/settings/officer',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Timecard For Officer
     * @param startDate
     * @param endDate
     * @returns OfficerTimecard Successful Response
     * @throws ApiError
     */
    public static getTimecardForOfficerApiV1TimecardsOfficerPeriodGet(
        startDate: string,
        endDate: string,
    ): CancelablePromise<Array<OfficerTimecard>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/timecards/officer/period',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Lock Shift Instances For Date
     * @param startDate
     * @param endDate
     * @param requestBody
     * @returns ScheduleShiftInstance Successful Response
     * @throws ApiError
     */
    public static lockShiftInstancesForDateApiV1TimecardsShiftsLockPut(
        startDate: string,
        endDate: string,
        requestBody: Array<string>,
    ): CancelablePromise<Array<ScheduleShiftInstance>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/timecards_shifts/lock',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unlock Shift Instances For Date
     * @param startDate
     * @param endDate
     * @param requestBody
     * @returns ScheduleShiftInstance Successful Response
     * @throws ApiError
     */
    public static unlockShiftInstancesForDateApiV1TimecardsShiftsUnlockPut(
        startDate: string,
        endDate: string,
        requestBody: Array<string>,
    ): CancelablePromise<Array<ScheduleShiftInstance>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/timecards_shifts/unlock',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Officers For Date Range
     * used to load officer info for the timekeeping pay period view
     * @param startDate
     * @param endDate
     * @returns OfficerForTimekeepingView Successful Response
     * @throws ApiError
     */
    public static getOfficersForDateRangeApiV1TimecardsPayrollPeriodOfficersGet(
        startDate: string,
        endDate: string,
    ): CancelablePromise<Array<OfficerForTimekeepingView>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/timecards/payroll_period/officers',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Shift Instances
     * Updates the shift instances with timekeeping related data.
     *
     * Only returns the shift instances impacted by the request.
     * @param startDate
     * @param endDate
     * @param requestBody
     * @param targetDate
     * @returns ScheduleShiftInstance Successful Response
     * @throws ApiError
     */
    public static updateShiftInstancesApiV2TimecardsShiftsUpdatePut(
        startDate: string,
        endDate: string,
        requestBody: Array<ShiftInstanceTimekeepingUpdate>,
        targetDate?: (string | null),
    ): CancelablePromise<Array<ScheduleShiftInstance>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v2/timecards_shifts/update',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'target_date': targetDate,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Timecards Export
     * @param kind
     * @param startDate
     * @param endDate
     * @param includeUnapproved
     * @param payGroupId
     * @param columnsToExclude
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getTimecardsExportApiV2TimecardsExportGet(
        kind: PayrollServicesEnum,
        startDate: string,
        endDate: string,
        includeUnapproved: boolean = false,
        payGroupId?: (string | null),
        columnsToExclude?: (Array<string> | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/timecards/export',
            query: {
                'kind': kind,
                'start_date': startDate,
                'end_date': endDate,
                'include_unapproved': includeUnapproved,
                'pay_group_id': payGroupId,
                'columns_to_exclude': columnsToExclude,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Shift Instances For Date Range To Payroll
     * processes shift instances, salaried workers, etc to create a draft checkhq payroll run and return the id
     * @param startDate
     * @param endDate
     * @param requestBody
     * @param payGroupId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static sendShiftInstancesForDateRangeToPayrollApiV2TimecardsShiftsPayrollPeriodPost(
        startDate: string,
        endDate: string,
        requestBody: Body_send_shift_instances_for_date_range_to_payroll_api_v2_timecards_shifts_payroll_period_post,
        payGroupId?: (string | null),
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/timecards_shifts/payroll_period',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'pay_group_id': payGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Non Hourly Pay For Date Range To Payroll
     * returns info about what salaried/flat-rate workers will be paid for the given pay period as well as other types of comp
     * @param startDate
     * @param endDate
     * @param payGroupId
     * @returns FlatratePayrollResponse Successful Response
     * @throws ApiError
     */
    public static getNonHourlyPayForDateRangeToPayrollApiV2TimecardsNonHourlyPayrollPeriodGet(
        startDate: string,
        endDate: string,
        payGroupId?: (string | null),
    ): CancelablePromise<FlatratePayrollResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/timecards_non_hourly/payroll_period',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'pay_group_id': payGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Shift Details
     * @param shiftInstanceId
     * @param shiftDetailId
     * @param startDate
     * @param endDate
     * @param requestBody
     * @param targetDate
     * @returns ScheduleShiftInstance Successful Response
     * @throws ApiError
     */
    public static updateShiftDetailsApiV2TimekeepingShiftInstanceIdDetailsShiftDetailIdPut(
        shiftInstanceId: string,
        shiftDetailId: string,
        startDate: string,
        endDate: string,
        requestBody: ShiftDetailsUpdate,
        targetDate?: (string | null),
    ): CancelablePromise<Array<ScheduleShiftInstance>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v2/timekeeping/{shift_instance_id}/details/{shift_detail_id}',
            path: {
                'shift_instance_id': shiftInstanceId,
                'shift_detail_id': shiftDetailId,
            },
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'target_date': targetDate,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Shift Instances
     * Updates the shift instances with timekeeping related data.
     *
     * Only returns the shift instances impacted by the request.
     * @param startDate
     * @param endDate
     * @param requestBody
     * @param targetDate
     * @returns TimecardShiftInstanceLegacy Successful Response
     * @throws ApiError
     */
    public static updateShiftInstancesApiV3TimecardsShiftsUpdatePut(
        startDate: string,
        endDate: string,
        requestBody: Array<ShiftInstanceTimekeepingUpdate>,
        targetDate?: (string | null),
    ): CancelablePromise<Array<TimecardShiftInstanceLegacy>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/timecards_shifts/update',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'target_date': targetDate,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shift Instances For Date Range
     * Returns the published shift instances for between start and end dates for firm
     * @param startDate
     * @param endDate
     * @param payGroupId
     * @param targetDate
     * @returns TimecardShiftInstanceLegacy Successful Response
     * @throws ApiError
     */
    public static getShiftInstancesForDateRangeApiV3TimecardsShiftsPeriodGet(
        startDate: string,
        endDate: string,
        payGroupId?: (string | null),
        targetDate?: (string | null),
    ): CancelablePromise<Array<TimecardShiftInstanceLegacy>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/timecards_shifts/period',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'pay_group_id': payGroupId,
                'target_date': targetDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Shift Details
     * @param shiftInstanceId
     * @param shiftDetailId
     * @param startDate
     * @param endDate
     * @param requestBody
     * @param targetDate
     * @returns TimecardShiftInstanceLegacy Successful Response
     * @throws ApiError
     */
    public static updateShiftDetailsApiV3TimekeepingShiftInstanceIdDetailsShiftDetailIdPut(
        shiftInstanceId: string,
        shiftDetailId: string,
        startDate: string,
        endDate: string,
        requestBody: ShiftDetailsUpdate,
        targetDate?: (string | null),
    ): CancelablePromise<Array<TimecardShiftInstanceLegacy>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/timekeeping/{shift_instance_id}/details/{shift_detail_id}',
            path: {
                'shift_instance_id': shiftInstanceId,
                'shift_detail_id': shiftDetailId,
            },
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'target_date': targetDate,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Shift Instances For Date Range To Payroll
     * processes shift instances, salaried workers, etc to create a draft payroll in Belfry
     * and payroll processor (Check) run and return the id of the payroll.
     * @param startDate
     * @param endDate
     * @param requestBody
     * @param payGroupId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static sendShiftInstancesForDateRangeToPayrollApiV3TimecardsShiftsPayrollPeriodPost(
        startDate: string,
        endDate: string,
        requestBody: Body_send_shift_instances_for_date_range_to_payroll_api_v3_timecards_shifts_payroll_period_post,
        payGroupId?: (string | null),
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/timecards_shifts/payroll_period',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'pay_group_id': payGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Shift Instances For Date Range To Payroll Rollout
     * Temporary - for rolling out payroll V2 only. Will be removed after!
     *
     * processes shift instances, salaried workers, etc to create a draft payroll in Belfry
     * and payroll processor (Check) run and return the id of the payroll.
     * @param startDate
     * @param endDate
     * @param payrollMode
     * @param requestBody
     * @param payGroupId
     * @returns PayrollV2RolloutResponse Successful Response
     * @throws ApiError
     */
    public static sendShiftInstancesForDateRangeToPayrollRolloutApiV3TimecardsShiftsPayrollPeriodRolloutPost(
        startDate: string,
        endDate: string,
        payrollMode: 'REGULAR' | 'SIDE-BY-SIDE' | 'SHADOW',
        requestBody: Body_send_shift_instances_for_date_range_to_payroll_rollout_api_v3_timecards_shifts_payroll_period_rollout_post,
        payGroupId?: (string | null),
    ): CancelablePromise<PayrollV2RolloutResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/timecards_shifts/payroll_period/rollout',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'payroll_mode': payrollMode,
                'pay_group_id': payGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unlock Shift Instances For Date
     * @param startDate
     * @param endDate
     * @param requestBody
     * @param payGroupId
     * @returns TimecardShiftInstanceLegacy Successful Response
     * @throws ApiError
     */
    public static unlockShiftInstancesForDateApiV3TimecardsShiftsUnlockPut(
        startDate: string,
        endDate: string,
        requestBody: Array<string>,
        payGroupId?: (string | null),
    ): CancelablePromise<Array<TimecardShiftInstanceLegacy>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/timecards_shifts/unlock',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'pay_group_id': payGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Lock Shift Instances For Date
     * @param startDate
     * @param endDate
     * @param requestBody
     * @param payGroupId
     * @returns TimecardShiftInstanceLegacy Successful Response
     * @throws ApiError
     */
    public static lockShiftInstancesForDateApiV3TimecardsShiftsLockPut(
        startDate: string,
        endDate: string,
        requestBody: Array<string>,
        payGroupId?: (string | null),
    ): CancelablePromise<Array<TimecardShiftInstanceLegacy>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/timecards_shifts/lock',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'pay_group_id': payGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
