/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BreakInstance } from '../models/BreakInstance';
import type { BreakInstanceCreate } from '../models/BreakInstanceCreate';
import type { BreakInstanceUpdate } from '../models/BreakInstanceUpdate';
import type { OfficerLocationCreate } from '../models/OfficerLocationCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BreakInstanceService {

    /**
     * Employee Start Break For Break Allotment And Shift Instance
     * Start a break (instance) for the given break allotment id and shift instance id. Error if no more break instances (this may happen if the fetching of the break allotment data is stale)
     * @param breakAllotmentId
     * @param shiftInstanceId
     * @param requestBody
     * @returns BreakInstance Successful Response
     * @throws ApiError
     */
    public static employeeStartBreakForBreakAllotmentAndShiftInstanceApiV1BreakStartBreakAllotmentBreakAllotmentIdShiftInstanceShiftInstanceIdPost(
        breakAllotmentId: string,
        shiftInstanceId: string,
        requestBody?: (OfficerLocationCreate | null),
    ): CancelablePromise<BreakInstance> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/break_start/break-allotment/{break_allotment_id}/shift-instance/{shift_instance_id}',
            path: {
                'break_allotment_id': breakAllotmentId,
                'shift_instance_id': shiftInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Employee Read Breaks For Shift Instance
     * Return the break instances for the given shift instance
     * @param shiftId
     * @param date
     * @param index
     * @returns BreakInstance Successful Response
     * @throws ApiError
     */
    public static employeeReadBreaksForShiftInstanceApiV1BreaksForShiftIdDateIndexGet(
        shiftId: string,
        date: string,
        index: number,
    ): CancelablePromise<Array<BreakInstance>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/breaks_for/{shift_id}/{date}/{index}',
            path: {
                'shift_id': shiftId,
                'date': date,
                'index': index,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * End Break
     * @param id
     * @param requestBody
     * @returns BreakInstance Successful Response
     * @throws ApiError
     */
    public static endBreakApiV1BreakEndIdPost(
        id: string,
        requestBody?: (OfficerLocationCreate | null),
    ): CancelablePromise<BreakInstance> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/break_end/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Break
     * @param id
     * @returns BreakInstance Successful Response
     * @throws ApiError
     */
    public static deleteBreakApiV1AdminBreakIdDelete(
        id: string,
    ): CancelablePromise<BreakInstance> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/admin_break/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Break
     * @param requestBody
     * @returns BreakInstance Successful Response
     * @throws ApiError
     */
    public static editBreakApiV1AdminBreakPut(
        requestBody: BreakInstanceUpdate,
    ): CancelablePromise<BreakInstance> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/admin_break/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Break
     * @param requestBody
     * @returns BreakInstance Successful Response
     * @throws ApiError
     */
    public static createBreakApiV1AdminBreakPost(
        requestBody: BreakInstanceCreate,
    ): CancelablePromise<BreakInstance> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin_break/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
