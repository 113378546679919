/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EmployeeProfileCreate } from './EmployeeProfileCreate';
import type { EmployeeProfileUpdate } from './EmployeeProfileUpdate';
import type { OfficerBannedSiteUpdate } from './OfficerBannedSiteUpdate';
import type { OfficerCategory } from './OfficerCategory';
import type { OfficerStatus } from './OfficerStatus';
import type { OfficerType } from './OfficerType';
import type { PostalAddress } from './PostalAddress';
import type { PreferredScheduleUpdate } from './PreferredScheduleUpdate';

export type OfficerUpdate = {
    name?: string;
    id?: string;
    given_name?: string;
    middle_name?: string;
    family_name?: string;
    nickname?: string;
    address?: string;
    postal_address?: PostalAddress;
    image_url?: string;
    phone_number?: string;
    hire_date?: string;
    date_of_birth?: string;
    email?: string;
    emergency_contact_name?: string;
    emergency_contact_phone_number?: string;
    employee_id?: string;
    employee_type?: OfficerUpdate.employee_type;
    is_terminated?: boolean;
    termination_date?: string;
    termination_reason?: string;
    status?: OfficerStatus;
    category?: OfficerCategory;
    type?: OfficerType;
    default_pay_rate?: number;
    bill_rate?: number;
    sms_delivery_failure?: boolean;
    role_id?: string;
    always_respect_officer_pay_rate?: boolean;
    exempt_employee?: boolean;
    salaried?: boolean;
    payroll_primary_workplace_customer_id?: (string | null);
    payroll_primary_workplace_non_customer_id?: (string | null);
    checkhq_id?: string;
    checkhq_contractor_id?: string;
    is_payroll_disabled?: boolean;
    job_title_id?: (string | null);
    workers_comp_code?: string;
    salary_weekly_hours?: number;
    clasp_member_id?: string;
    clasp_subclasses?: Array<string>;
    preferredschedule?: Array<PreferredScheduleUpdate>;
    banned_customers?: Array<OfficerBannedSiteUpdate>;
    region_ids?: Array<string>;
    supervisor_site_ids?: Array<string>;
    checkhq_emp_onboard_status?: string;
    checkhq_emp_ssn_val_status?: string;
    checkhq_con_onboard_status?: string;
    checkhq_con_ssn_val_status?: string;
    profile_in?: (EmployeeProfileCreate | EmployeeProfileUpdate);
    onboarding_template_id?: string;
};

export namespace OfficerUpdate {

    export enum employee_type {
        SCHEDULABLE = 'Schedulable',
        NOT_SCHEDULABLE = 'Not Schedulable',
    }


}

