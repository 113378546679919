/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum RunsheetTaskType {
    REPORT = 'Report',
    ADMINISTRATIVE = 'Administrative',
    SITE_VISIT = 'Site Visit',
    DISPATCH = 'Dispatch',
}
