/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum app__enums__chat__ChatPermission {
    GROUP_CHAT_CREATE = 'GROUP_CHAT_CREATE',
    DIRECT_MESSAGE_CREATE = 'DIRECT_MESSAGE_CREATE',
    CAN_MESSAGE_ADMIN = 'CAN_MESSAGE_ADMIN',
    CAN_MESSAGE_FINANCE = 'CAN_MESSAGE_FINANCE',
    CAN_MESSAGE_HR = 'CAN_MESSAGE_HR',
    CAN_MESSAGE_OFFICER = 'CAN_MESSAGE_OFFICER',
    CAN_MESSAGE_SUPERVISOR = 'CAN_MESSAGE_SUPERVISOR',
    CAN_MESSAGE_MANAGER = 'CAN_MESSAGE_MANAGER',
}
