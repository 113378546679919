/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActivityCustomer } from './ActivityCustomer';
import type { ActivityOfficer } from './ActivityOfficer';
import type { ActivityType } from './ActivityType';
import type { OfficerLocation } from './OfficerLocation';

export type ActivityWithLocation = {
    activity_type: ActivityType;
    description?: string;
    id: string;
    title: string;
    index?: number;
    date?: string;
    shift_id?: string;
    customer_id: string;
    site_visit_id?: string;
    patrol_site?: ActivityCustomer;
    officer_id: string;
    officer: ActivityOfficer;
    created_at: string;
    occurred_at: string;
    post_name?: string;
    location?: string;
    activity_type_literal: ActivityWithLocation.activity_type_literal;
    officer_location?: OfficerLocation;
};

export namespace ActivityWithLocation {

    export enum activity_type_literal {
        ACTIVITY_REPORT_FILED = 'ACTIVITY_REPORT_FILED',
        BREAK_IN = 'BREAK_IN',
        BREAK_OUT = 'BREAK_OUT',
        CHECK_IN = 'CHECK_IN',
        CHECK_OUT = 'CHECK_OUT',
        CLOCK_IN = 'CLOCK_IN',
        CLOCK_OUT = 'CLOCK_OUT',
        CUSTOM_REPORT_FILED = 'CUSTOM_REPORT_FILED',
        INCIDENT_REPORT_FILED = 'INCIDENT_REPORT_FILED',
        TOUR_STARTED = 'TOUR_STARTED',
        CHECKPOINT_SCANNED = 'CHECKPOINT_SCANNED',
        TOUR_ENDED = 'TOUR_ENDED',
        POST_ORDER_ACKNOWLEDGED = 'POST_ORDER_ACKNOWLEDGED',
    }


}

