/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PayrollWorkplace = {
    id: string;
    processor_id: string;
    name: string;
    workplace_type: PayrollWorkplace.workplace_type;
};

export namespace PayrollWorkplace {

    export enum workplace_type {
        CUSTOMER = 'customer',
        NON_CUSTOMER = 'non-customer',
    }


}

