/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OfficerMetadata } from './OfficerMetadata';
import type { SupplementalPayBenefitInstanceDataClass } from './SupplementalPayBenefitInstanceDataClass';

export type TimeOff = {
    /**
     * Note associated with the time off.
     */
    note?: string;
    /**
     * Note set by back office to be viewed by back office only.
     */
    note_for_back_office?: string;
    /**
     * Note set by back office to be viewed by officer.
     */
    note_for_officer?: string;
    /**
     * If true, the time off details were edited by admin..
     */
    edited_by_back_office?: boolean;
    /**
     * Start time that the time off blocks the employee from being scheduled.
     */
    unschedulable_start_date_time?: string;
    /**
     * End time that the time off blocks the employee from being scheduled.
     */
    unschedulable_end_date_time?: string;
    id: string;
    /**
     * Time that the time off starts.
     */
    start_date_time: string;
    /**
     * Time that the time off ends.
     */
    end_date_time: string;
    /**
     * Time that the original time off entry was created.
     */
    created_at: string;
    /**
     * Most recent time that the time off entry was changed.
     */
    updated_at?: string;
    /**
     * Id of the user who created the time off request.
     */
    approved_by_id: string;
    /**
     * Employee taking the time off
     */
    employee: OfficerMetadata;
    /**
     * Name of employee who approved the time off
     */
    approved_by_name: string;
    /**
     * Name of policy the time off is associated with.
     */
    policy_name: string;
    /**
     * Id of the time off policy this time off is associated with.
     */
    policy_id: string;
    /**
     * Number of minutes this time off counts towards as. Factors in overrides, policy type, etc.
     */
    minutes_for_time_off: number;
    /**
     * Type of policy: PTO, SICK, or UNPAID.
     */
    policy_type: TimeOff.policy_type;
    /**
     * Total amount paid for this time off period. 0 for salaried as their comp is different.
     */
    total_time_off_comp: number;
    /**
     * Does this time off apply to hourly or salaried workers.
     */
    applied_to_hourly: boolean;
    /**
     * Rate (in cents per hour) this time off is paid at.
     */
    rate_for_time_off: number;
    /**
     * Supplemental Pay Benefit (in cents) paid for this time off.
     */
    spb_cents?: number;
    /**
     * Supplemental Pay Benefit Instances for this time off
     */
    spb_instances?: Array<SupplementalPayBenefitInstanceDataClass>;
    /**
     * Id of the site this time off is associated with.
     */
    customer_id?: string;
    /**
     * Name of the site this time off is associated with.
     */
    customer_name?: string;
    /**
     * Maps the site this time off is associated with the check id
     */
    checkhq_id?: string;
    /**
     * The id of the payroll run associated with this time off
     */
    checkhq_payroll_id?: string;
    /**
     * IANA timezone of the site this time off is associated with.
     */
    iana_timezone: string;
    /**
     * Whether the time off is locked for editing
     */
    is_locked?: boolean;
    /**
     * Availability block this time off is linked to.
     */
    availability_block_id?: string;
    /**
     * The id of the payroll run associated with this time off (V2).
     */
    payroll_id?: string;
};

export namespace TimeOff {

    /**
     * Type of policy: PTO, SICK, or UNPAID.
     */
    export enum policy_type {
        PTO = 'PTO',
        SICK = 'SICK',
        UNPAID = 'UNPAID',
    }


}

