/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdHocInvoiceCreate } from '../models/AdHocInvoiceCreate';
import type { app__enums__invoice_enums__InvoiceAction } from '../models/app__enums__invoice_enums__InvoiceAction';
import type { app__schemas__invoice__InvoiceAction } from '../models/app__schemas__invoice__InvoiceAction';
import type { EnrichedInvoice } from '../models/EnrichedInvoice';
import type { FileCreate } from '../models/FileCreate';
import type { HighLevelEnrichedInvoice } from '../models/HighLevelEnrichedInvoice';
import type { Invoice } from '../models/Invoice';
import type { InvoiceActionResponse } from '../models/InvoiceActionResponse';
import type { InvoiceCreate } from '../models/InvoiceCreate';
import type { InvoiceUpdate } from '../models/InvoiceUpdate';
import type { PaymentConfiguration } from '../models/PaymentConfiguration';
import type { PaymentInInvoice } from '../models/PaymentInInvoice';
import type { SendgridWebhookEvent } from '../models/SendgridWebhookEvent';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InvoiceService {

    /**
     * Get Invoices
     * Return the info to generate upcoming invoices and existing invoices
     * @returns HighLevelEnrichedInvoice Successful Response
     * @throws ApiError
     */
    public static getInvoicesApiV1InvoicesGet(): CancelablePromise<Array<HighLevelEnrichedInvoice>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/invoices',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Save Invoice
     * @param requestBody
     * @returns EnrichedInvoice Successful Response
     * @throws ApiError
     */
    public static saveInvoiceApiV1InvoicePost(
        requestBody: InvoiceCreate,
    ): CancelablePromise<EnrichedInvoice> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/invoice',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Ad Hoc Invoice
     * @param requestBody
     * @returns EnrichedInvoice Successful Response
     * @throws ApiError
     */
    public static createAdHocInvoiceApiV1InvoiceAdhocPost(
        requestBody: AdHocInvoiceCreate,
    ): CancelablePromise<EnrichedInvoice> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/invoice/adhoc',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Finalize And Send Invoice
     * @param invoiceId
     * @param invoiceAction
     * @param disablePaymentLink
     * @param enablePassThroughFees
     * @returns EnrichedInvoice Successful Response
     * @throws ApiError
     */
    public static finalizeAndSendInvoiceApiV1InvoiceFinalizeSendPost(
        invoiceId: string,
        invoiceAction: app__enums__invoice_enums__InvoiceAction,
        disablePaymentLink?: (boolean | null),
        enablePassThroughFees?: (boolean | null),
    ): CancelablePromise<EnrichedInvoice> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/invoice/finalize_send',
            query: {
                'invoice_id': invoiceId,
                'invoice_action': invoiceAction,
                'disable_payment_link': disablePaymentLink,
                'enable_pass_through_fees': enablePassThroughFees,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Record Payment
     * @param invoiceId
     * @param requestBody
     * @returns EnrichedInvoice Successful Response
     * @throws ApiError
     */
    public static recordPaymentApiV1InvoicePaymentPut(
        invoiceId: string,
        requestBody: PaymentInInvoice,
    ): CancelablePromise<EnrichedInvoice> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/invoice/payment',
            query: {
                'invoice_id': invoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Invoice
     * @param invoiceId
     * @param requestBody
     * @returns Invoice Successful Response
     * @throws ApiError
     */
    public static updateInvoiceApiV1InvoiceFullInvoiceIdPut(
        invoiceId: string,
        requestBody: InvoiceUpdate,
    ): CancelablePromise<Invoice> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/invoice_full/{invoice_id}',
            path: {
                'invoice_id': invoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Number
     * @param invoiceId
     * @param newInvoiceNumber
     * @returns EnrichedInvoice Successful Response
     * @throws ApiError
     */
    public static editNumberApiV1InvoiceNumberPut(
        invoiceId: string,
        newInvoiceNumber: string,
    ): CancelablePromise<EnrichedInvoice> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/invoice/number',
            query: {
                'invoice_id': invoiceId,
                'new_invoice_number': newInvoiceNumber,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Nascent Invoice
     * @param contractId
     * @param startDate
     * @param endDate
     * @param includeShiftInstances
     * @returns EnrichedInvoice Successful Response
     * @throws ApiError
     */
    public static getNascentInvoiceApiV1InvoiceNascentContractIdStartDateEndDateGet(
        contractId: string,
        startDate: string,
        endDate: string,
        includeShiftInstances: boolean = false,
    ): CancelablePromise<EnrichedInvoice> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/invoice/nascent/{contract_id}/{start_date}/{end_date}',
            path: {
                'contract_id': contractId,
                'start_date': startDate,
                'end_date': endDate,
            },
            query: {
                'include_shift_instances': includeShiftInstances,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Nascent Invoice
     * @param contractId
     * @param startDate
     * @param endDate
     * @returns EnrichedInvoice Successful Response
     * @throws ApiError
     */
    public static removeNascentInvoiceApiV1InvoiceNascentContractIdStartDateEndDateDelete(
        contractId: string,
        startDate: string,
        endDate: string,
    ): CancelablePromise<Array<EnrichedInvoice>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/invoice/nascent/{contract_id}/{start_date}/{end_date}',
            path: {
                'contract_id': contractId,
                'start_date': startDate,
                'end_date': endDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Existing Invoice
     * @param invoiceId
     * @returns EnrichedInvoice Successful Response
     * @throws ApiError
     */
    public static getExistingInvoiceApiV1InvoiceExistingInvoiceIdGet(
        invoiceId: string,
    ): CancelablePromise<EnrichedInvoice> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/invoice/existing/{invoice_id}',
            path: {
                'invoice_id': invoiceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Shift Instance In Invoice
     * @param invoiceId
     * @param shiftInstanceId
     * @param start
     * @param end
     * @param adjBillingRate
     * @param notes
     * @returns EnrichedInvoice Successful Response
     * @throws ApiError
     */
    public static editShiftInstanceInInvoiceApiV1InvoiceInvoiceIdInstanceShiftInstanceIdPut(
        invoiceId: string,
        shiftInstanceId: string,
        start: string,
        end: string,
        adjBillingRate: number,
        notes?: (string | null),
    ): CancelablePromise<EnrichedInvoice> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/invoice/{invoice_id}/instance/{shift_instance_id}',
            path: {
                'invoice_id': invoiceId,
                'shift_instance_id': shiftInstanceId,
            },
            query: {
                'start': start,
                'end': end,
                'adj_billing_rate': adjBillingRate,
                'notes': notes,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Shift Instances In Invoice
     * @param invoiceId
     * @param requestBody
     * @returns EnrichedInvoice Successful Response
     * @throws ApiError
     */
    public static deleteShiftInstancesInInvoiceApiV1InvoiceInvoiceIdInstancesDelete(
        invoiceId: string,
        requestBody: Array<string>,
    ): CancelablePromise<EnrichedInvoice> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/invoice/{invoice_id}/instances',
            path: {
                'invoice_id': invoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Invoice
     * @param invoiceId
     * @returns EnrichedInvoice Successful Response
     * @throws ApiError
     */
    public static deleteInvoiceApiV1InvoiceInvoiceIdDelete(
        invoiceId: string,
    ): CancelablePromise<Array<EnrichedInvoice>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/invoice/{invoice_id}',
            path: {
                'invoice_id': invoiceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add File To Invoice
     * @param invoiceId
     * @param requestBody
     * @returns EnrichedInvoice Successful Response
     * @throws ApiError
     */
    public static addFileToInvoiceApiV1InvoiceInvoiceIdFilesPost(
        invoiceId: string,
        requestBody: FileCreate,
    ): CancelablePromise<EnrichedInvoice> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/invoice/{invoice_id}/files',
            path: {
                'invoice_id': invoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove File From Invoice
     * @param invoiceId
     * @param fileId
     * @returns EnrichedInvoice Successful Response
     * @throws ApiError
     */
    public static removeFileFromInvoiceApiV1InvoiceInvoiceIdFilesDelete(
        invoiceId: string,
        fileId: string,
    ): CancelablePromise<EnrichedInvoice> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/invoice/{invoice_id}/files',
            path: {
                'invoice_id': invoiceId,
            },
            query: {
                'file_id': fileId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Pdf For Invoice
     * Return the invoice as pdf
     * @param invoiceId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createPdfForInvoiceApiV1InvoicePdfInvoiceIdGet(
        invoiceId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/invoice_pdf/{invoice_id}',
            path: {
                'invoice_id': invoiceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Pdf For Invoice No User
     * Return the invoice as pdf for payment enabled firms
     * @param invoiceId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createPdfForInvoiceNoUserApiV1InvoicePdfInvoiceIdPaymentGet(
        invoiceId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/invoice_pdf/{invoice_id}/payment',
            path: {
                'invoice_id': invoiceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Export Csv Invoices
     * Return the invoice as pdf
     * @param start
     * @param end
     * @returns any Successful Response
     * @throws ApiError
     */
    public static exportCsvInvoicesApiV1InvoicesExportCsvStartEndGet(
        start: string,
        end: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/invoices_export_csv/{start}/{end}',
            path: {
                'start': start,
                'end': end,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Shift Instance Group In Invoice
     * @param invoiceId
     * @param shiftInstanceGroupId
     * @param description
     * @returns EnrichedInvoice Successful Response
     * @throws ApiError
     */
    public static editShiftInstanceGroupInInvoiceApiV1InvoiceInvoiceIdInstanceGroupShiftInstanceGroupIdPut(
        invoiceId: string,
        shiftInstanceGroupId: string,
        description?: (string | null),
    ): CancelablePromise<EnrichedInvoice> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/invoice/{invoice_id}/instance_group/{shift_instance_group_id}',
            path: {
                'invoice_id': invoiceId,
                'shift_instance_group_id': shiftInstanceGroupId,
            },
            query: {
                'description': description,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Sendgrid Webhook
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendgridWebhookApiV1InvoiceSendgridWebhookPost(
        requestBody: Array<SendgridWebhookEvent>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/invoice/sendgrid_webhook',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Payment Configuration
     * Returns payment configuration object pertaining to this invoice.
     * @param invoiceId
     * @returns PaymentConfiguration Successful Response
     * @throws ApiError
     */
    public static getPaymentConfigurationApiV1InvoicesInvoiceIdPaymentsConfigurationGet(
        invoiceId: string,
    ): CancelablePromise<PaymentConfiguration> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/invoices/{invoice_id}/payments/configuration',
            path: {
                'invoice_id': invoiceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Execute Invoice Action
     * @param invoiceId
     * @param requestBody
     * @returns InvoiceActionResponse Successful Response
     * @throws ApiError
     */
    public static executeInvoiceActionApiV1InvoicesInvoiceIdActionPut(
        invoiceId: string,
        requestBody: app__schemas__invoice__InvoiceAction,
    ): CancelablePromise<InvoiceActionResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/invoices/{invoice_id}/action',
            path: {
                'invoice_id': invoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Existing Invoices Endpoint
     * Returns all of the 'real' invoices that have been written to the DB
     *
     * Obvious target for pagination, but not yet implemented.
     * @returns HighLevelEnrichedInvoice Successful Response
     * @throws ApiError
     */
    public static getExistingInvoicesEndpointApiV2InvoicesExistingGet(): CancelablePromise<Array<HighLevelEnrichedInvoice>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/invoices/existing',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Nascent Invoices Endpoint
     * Returns all the 'possible' invoices we suggest to generate based on contract settings.
     * @param minDate
     * @param maxDate
     * @returns HighLevelEnrichedInvoice Successful Response
     * @throws ApiError
     */
    public static getNascentInvoicesEndpointApiV2InvoicesNascentGet(
        minDate?: (string | null),
        maxDate?: (string | null),
    ): CancelablePromise<Array<HighLevelEnrichedInvoice>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/invoices/nascent',
            query: {
                'min_date': minDate,
                'max_date': maxDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
