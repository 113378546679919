/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OfficerLocationCreate } from '../models/OfficerLocationCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * Add Location
     * @param id
     * @param requestBody
     * @param ignoreClockState
     * @returns any Successful Response
     * @throws ApiError
     */
    public static addLocationApiV1AssignmentLocationIdPost(
        id: string,
        requestBody: OfficerLocationCreate,
        ignoreClockState: boolean = false,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/assignment/location/{id}',
            path: {
                'id': id,
            },
            query: {
                'ignore_clock_state': ignoreClockState,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Status Check
     * Health check for the backend server.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static statusCheckApiV1HealthGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/health',
        });
    }

    /**
     * Payment Processing Webhook
     * The Webhook we use to receive payment processing events/data from Payabli
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static paymentProcessingWebhookApiV1WebhookPaymentsProcessingPost(
        requestBody: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/webhook/payments/processing',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Webhook
     * The Webhook we use to receive events/data from CheckHQ
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static payrollWebhookApiV1WebhookPayrollPost(
        requestBody: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/webhook/payroll',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
