/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DispatchActivityLog } from '../models/DispatchActivityLog';
import type { DispatchStatus } from '../models/DispatchStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DispatchActivityLogService {

    /**
     * Get Dispatch Activity Logs
     * @param dispatchId
     * @param status
     * @returns DispatchActivityLog Successful Response
     * @throws ApiError
     */
    public static getDispatchActivityLogsApiV1DispatchDispatchIdActivityLogsGet(
        dispatchId: string,
        status?: (DispatchStatus | null),
    ): CancelablePromise<Array<DispatchActivityLog>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dispatch/{dispatch_id}/activity_logs',
            path: {
                'dispatch_id': dispatchId,
            },
            query: {
                'status': status,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
