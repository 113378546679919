import {
    ApiError,
    SecuritySettings,
    Settings,
    SettingsService,
} from '../generated'
import useSWR, { KeyedMutator } from 'swr'
import { toast } from 'react-toastify'
import { errorReasonToString } from '../utils/errorUtils'
import { SettingsForEmployeePortal } from 'src/generated/models/SettingsForEmployeePortal'

type SettingsType = {
    settings: Settings | undefined
    isLoading: boolean
    isError: boolean
}

type GetSettingsFunc = () => SettingsType
export const useGetSettings: GetSettingsFunc = () => {
    const { data, error } = useSWR(
        `/settings`,
        SettingsService.getSettingsApiV1SettingsGet
    )
    return {
        settings: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type SettingsForEmployeePortalType = {
    settings: SettingsForEmployeePortal | undefined
    isLoading: boolean
    isError: boolean
}

type GetSettingsForEmployeePortalFunc = () => SettingsForEmployeePortalType
export const useGetSettingsForEmployeePortal: GetSettingsForEmployeePortalFunc =
    () => {
        const { data, error } = useSWR(
            `/employee_portal/settings`,
            SettingsService.loadFirmSettingsForEmployeePortalApiV1EmployeePortalSettingsGet
        )
        return {
            settings: data,
            isLoading: !error && !data,
            isError: error,
        }
    }

type UpdateSettingsFunc = (settings: Settings, mutate: any) => void
export const updateSettings: UpdateSettingsFunc = (settings, mutate) => {
    const options = { optimisticData: settings, rollbackOnError: true }
    mutate(
        `/settings`,
        () =>
            SettingsService.updateSettingsApiV1SettingsPost(settings).catch(
                (error) => toast.error(errorReasonToString(error))
            ),
        options
    )
}

type SecuritySettingsType = {
    settings: SecuritySettings | undefined
    isLoading: boolean
    isError: boolean
    mutate: KeyedMutator<SecuritySettings>
}

type GetSecuritySettingsFunc = () => SecuritySettingsType
export const useGetSecuritySettings: GetSecuritySettingsFunc = () => {
    const { data, error, mutate } = useSWR(
        `/security`,
        SettingsService.getSecuritySettingsApiV1SecurityGet
    )
    return {
        settings: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

type UpdateSecuritySettingsFunc = (
    settings: SecuritySettings,
    mutate: KeyedMutator<SecuritySettings>
) => Promise<void>
export const updateSecuritySettings: UpdateSecuritySettingsFunc = async (
    settings,
    mutate
) => {
    await mutate(async (existingSettings: SecuritySettings | undefined) => {
        try {
            return await SettingsService.updateSecuritySettingsApiV1SecurityPut(
                settings
            )
        } catch (error: any) {
            if (error instanceof ApiError) {
                toast.error(errorReasonToString(error))
            } else {
                toast.error(error.message)
            }
            return existingSettings
        }
    })
}
