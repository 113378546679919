/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BreakAllotment } from '../models/BreakAllotment';
import type { BreakAllotmentCreate } from '../models/BreakAllotmentCreate';
import type { BreakAllotmentUpdate } from '../models/BreakAllotmentUpdate';
import type { BreakAllotmentWithBreakInstancesRemaining } from '../models/BreakAllotmentWithBreakInstancesRemaining';
import type { BreakInstance } from '../models/BreakInstance';
import type { OfficerLocationCreate } from '../models/OfficerLocationCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BreakAllotmentService {

    /**
     * Read Break Allotments
     * Return the break_allotments for an admin user's firm.
     * @param onlyCustomerId
     * @returns BreakAllotment Successful Response
     * @throws ApiError
     */
    public static readBreakAllotmentsApiV1BreakAllotmentGet(
        onlyCustomerId?: (string | null),
    ): CancelablePromise<Array<BreakAllotment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/break_allotment',
            query: {
                'only_customer_id': onlyCustomerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Break Allotment
     * @param requestBody
     * @returns BreakAllotment Successful Response
     * @throws ApiError
     */
    public static createBreakAllotmentApiV1BreakAllotmentPost(
        requestBody: BreakAllotmentCreate,
    ): CancelablePromise<BreakAllotment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/break_allotment',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Employee Read Break Allotments For Shift Instance
     * Return the break instances for the given shift instance
     * @param shiftInstanceId
     * @returns BreakAllotmentWithBreakInstancesRemaining Successful Response
     * @throws ApiError
     */
    public static employeeReadBreakAllotmentsForShiftInstanceApiV1BreaksShiftInstanceIdGet(
        shiftInstanceId: string,
    ): CancelablePromise<Array<BreakAllotmentWithBreakInstancesRemaining>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/breaks/{shift_instance_id}',
            path: {
                'shift_instance_id': shiftInstanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Employee Start Break For Break Allotment And Shift Instance
     * Start a break (instance) for the given break allotment id and shift instance id. Error if no more break instances (this may happen if the fetching of the break allotment data is stale)
     * @param breakAllotmentId
     * @param shiftInstanceId
     * @param requestBody
     * @returns BreakInstance Successful Response
     * @throws ApiError
     */
    public static employeeStartBreakForBreakAllotmentAndShiftInstanceApiV1BreakStartBreakAllotmentBreakAllotmentIdShiftInstanceShiftInstanceIdPost(
        breakAllotmentId: string,
        shiftInstanceId: string,
        requestBody?: (OfficerLocationCreate | null),
    ): CancelablePromise<BreakInstance> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/break_start/break-allotment/{break_allotment_id}/shift-instance/{shift_instance_id}',
            path: {
                'break_allotment_id': breakAllotmentId,
                'shift_instance_id': shiftInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Break Allotment
     * @param id
     * @param requestBody
     * @returns BreakAllotment Successful Response
     * @throws ApiError
     */
    public static updateBreakAllotmentApiV1BreakAllotmentIdPut(
        id: string,
        requestBody: BreakAllotmentUpdate,
    ): CancelablePromise<BreakAllotment> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/break_allotment/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Break Allotment
     * @param id
     * @returns BreakAllotment Successful Response
     * @throws ApiError
     */
    public static deleteBreakAllotmentApiV1BreakAllotmentIdDelete(
        id: string,
    ): CancelablePromise<BreakAllotment> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/break_allotment/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
